 <div class="container">
    <h3 class="mb-2 ml-3 p-2" >Todos</h3>
     
    <app-add-todo 
    class="add-add-todo"
    (addTodo)="addTodo($event)"
    >
    </app-add-todo>
   <div class="row">
     
      <app-todo-item 
      class="col-lg-12" 
      *ngFor="let todo of todos" 
      [todo]="todo"
      (deleteTodo)="deleteTodo($event)"
      >
    
        </app-todo-item>
   </div>
 </div>
 
 