import { Component, OnInit , HostListener } from '@angular/core';
// import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router'
import { $ } from 'protractor';
import { GetNavHeightService } from "../../services/get-nav-height.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  thuis:boolean = true;

  showMenu: boolean = true;
  iets:any = document.getElementById("navbarColor02");
  innerWidth:number= window.innerWidth;
  main_navbar_height:number;

  height:number;

  navHeightVariable:number;

  constructor(
    // private authService:AuthService,
    private router:Router,
    private navVariable:GetNavHeightService
    ) { }

  ngOnInit() {

    this.navVariable.currentVariable.subscribe(height => this.navHeightVariable = height);

    const innerVanNav = window.innerWidth;
    if(innerVanNav > 575){
      this.showMenu = true;

    }
    if(innerVanNav < 576){
      this.showMenu = false;

    }

    //const main_navbar = document.getElementById('main_navbar');
    this.main_navbar_height = document.getElementById('main_navbar').clientHeight;
    //alert( this.main_navbar_height)

    //changing navVariable to the current nav height

    this.getNavHeight()

  }



  toggleMenu(){
    this.showMenu = !this.showMenu
  }

  toggleMenuIfMobile(){
    if(innerWidth < 576){
      this.showMenu = !this.showMenu

    }
  }

  onResize(event) {
    const innerVanNav = event.target.innerWidth;
    console.log(innerVanNav)
    if(innerVanNav > 575){
      this.showMenu = true;

    }
    if(innerVanNav < 576){
      this.showMenu = false;

    }
  }

  getNavHeight(){


    this.navVariable.changeVariable( this.main_navbar_height )
  }


  closeMenuAfterClickedOnList(){

  }









}
