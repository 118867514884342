import { Component, OnInit, Input } from '@angular/core';
import { Output , EventEmitter } from "@angular/core";
import { SharingDataServiceService } from "../sharing-data-service.service";

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.scss']
})
export class ChildComponent implements OnInit {

  @Input() someData:any;
  @Output() childEventEmitter = new EventEmitter<string>();

  message:string;

  constructor(private data: SharingDataServiceService) { }

  ngOnInit() {
    this.data.currentMessage.subscribe(value => this.message = value);
  }

  addNewItem(value: string){
    this.childEventEmitter.emit(value);
  }

  newMessageToService(value:string){
    this.data.changeMessage(value)
  }

}
