<div id="{{item.title}}"
    class="scroll-item {{item.title}}-background-color theme-{{colorTheme | async}}">
  <app-color-theme-switcher></app-color-theme-switcher>
  <h1>{{item.title}}</h1>
  <strong *ngIf="item.level != 'Ask me on LinkedIn'" class="item--strong">Level</strong>
  <span>{{item.level}}</span>
  <div *ngIf="item.CorporateExperience.length >= 1" >
    <strong class="item--strong">Corporate Experience</strong>
    <span class="d-block" *ngFor="let exp of item.CorporateExperience">
      {{exp}}
    </span>
  </div>
  <div class="next-skill-container">
    <span class="next-skill--block" (click)="prevSkill()">Previous</span>
    <span class="next-skill--block" (click)="nextSkill()">Next</span>
  </div>
</div>
