import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GetPokemonService {

  constructor(private http:HttpClient) { }

  //number:number;

  configUrl = 'https://pokeapi.co/api/v2/pokemon/ditto/  ';


  FrontImgUrl:any;

  number:any;
  

  getRandomPokemon(number){
    if(number == false || number == undefined || number == null ){
      number = Math.floor(Math.random()*100)*3+1

    }else{
      
    }
    this.FrontImgUrl = 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/'+number+'.png'
    

    return this.http.get(this.FrontImgUrl , { responseType: 'blob' });

  }

  getConfig() {
    return this.http.get(this.configUrl);
  }

}
