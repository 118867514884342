import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-overview-tile',
  templateUrl: './overview-tile.component.html',
  styleUrls: ['./overview-tile.component.scss'],
  animations: [
    trigger('hoveringState', [
      state('show', style({
        opacity: 1,
        paddingTop: "0px"
      })),
      state('hide',   style({
        opacity: 0,
        paddingTop: "270px"
      })),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('600ms ease-in'))
    ])
  ]
})
export class OverviewTileComponent implements OnInit {

  @Input() name;
  @Input() beginYear;
  @Input() endYear;
  @Input() img;
  @Input() subject;
  @Input() summary;
  @Input() hex;

  hovering = false;

  constructor() { }

  ngOnInit(): void {
  }

  show = false;

  get stateName() {
    return this.show ? 'show' : 'hide'
  }

  over  = () => {
    this.show = true;
  }

  out  = () => {
    this.show = false;
  }

}
