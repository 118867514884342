<nav
  class="navbar navbar-expand-sm navbar-dark navbarBgStandaard {{this.showMenu ? 'navbar-menu--shown' : 'navbar-menu--hidden'}}"
  id="main_navbar"
  (window:resize)="onResize($event)"
>
  <a *ngIf="thuis" class="navbar-brand" href="#">Arjen Holleman</a>
  <a *ngIf="!thuis" class="navbar-brand" href="#"
    ><img src="../../../assets/logos/ns_white_400.png"
  /></a>

  <button
    class="
      navbar-toggler
      "
    (click)="toggleMenu()"
    type="button"
    data-toggle="collapse"
    data-target="#navbarColor02"
    aria-controls="navbarColor02"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class=" navbar-collapse navbar-collapse-mobile" id="navbarColor02 " *ngIf="showMenu">
    <ul class="navbar-nav ml-auto  " (click)="toggleMenuIfMobile()">
      <li
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" [routerLink]="['/']"
          >Home <span class="sr-only">(current)</span></a
        >
      </li>
      <li
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" [routerLink]="['/skills-scroll']"
          >Skills <span class="sr-only">(current)</span></a
        >
      </li>
      <li
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" [routerLink]="['/werkervaring']"
          >Werkervaring <span class="sr-only">(current)</span></a
        >
      </li>
      <li
        *ngIf="false"
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" [routerLink]="['/werkervaring-overzicht']"
          >Werkervaring v2 <span class="sr-only">(current)</span></a
        >
      </li>
      <li
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" [routerLink]="['/werk']"
          >Werk<span class="sr-only">(current)</span></a
        >
      </li>
      <li
        class="nav-item"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a class="nav-link" [routerLink]="['/contact']"
          >Contact<span class="sr-only">(current)</span></a
        >
      </li>
    </ul>
  </div>
</nav>
