import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abbo-recommender',
  templateUrl: './abbo-recommender.component.html',
  styleUrls: ['./abbo-recommender.component.scss']
})
export class AbboRecommenderComponent implements OnInit {
 
  title:string='Proof of concept : Abonnementadvies op basis van reishistorie';

  constructor() { }

  ngOnInit() {
  }

}
