<div class="abbo_r">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4>{{ title }}</h4>
      </div>
      <div class="col-lg-6 video_foto-col">
        <p></p>
        <div class="video_container">
          <video controls [muted]="true" width="100%">
            <source
              src="../../../../assets/video/quick click through in CV Tool.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <div class="col-lg-6 text-col">
        <p>
          Voor Rockstars de Front-end van de CV Generator gemaakt tussen
          (klant-)projecten door.
        </p>

        <h6>Gebruikte tools/technieken:</h6>
        <p>
          Angular, Angular Material, Typescript, Javascript Azure Dev Ops
        </p>
      </div>
    </div>
  </div>
</div>
