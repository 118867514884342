<div class="abbo_r">
    <div class="container">
        <div class="row">
            <div class="col-lg-12"><h4>{{title}}</h4></div>
            <div class="col-lg-6 video_foto-col">
                
                <p>Front-end Javascript Injectie op de homepage van NS. Lees hieronder hoe dit experiment tot productie kwam.</p>
                <div class="video_container">
                  <h6 class="headerXS">Originele homepage</h6>
                  <img src="../../../../assets/werk_images/o flex desk.png" alt="Original in experiment"  class="devar_img first_image">  
                  
                  <h6 class="headerXS">Variant homepage (Arjens Front-end)</h6>
                  <img src="../../../../assets/werk_images/a desktop.png" alt="variant in experiment"  class="devar_img">
                    
                </div>
              </div>
              <div class="col-lg-6 text-col">
                <p>Een Designer bij NS kwam naar het Optimalisatie Team ( Arjens team ) met de hypothese dat de banner op de homepage beter zal presteren zodra het onder de reisplanner komt te staan. Na overleg met het team is Arjen aan de slag gegaan met de nieuwe front-end. 
                  De code werd door Arjen ontwikkeld in VS Code en vervolgens werd het geüpload in Optimizely, een A/B testing tool. 
                  Naast de front-end heeft Arjen ook hier het verzenden van data verzorgd via Relay42 / Google Analytics. Door data te versturen naar de Google Analytics & Optimizely API kon dit vervolgens getracked worden tijdens het verloop van de A/B Test.
                  De A/B test is significant succesvol gebleken en staat inmiddels live op productie bij de Nederlanse Spoorwegen. 
                </p>
  
                  <h6>Gebruikte tools/technieken:</h6>
                  <p>Javascript, Jquery, API's, Relay42, HTML, CSS, VS Code, Google Analytics, Optimizely </p>
                  
                  
              </div>
        </div>
    </div>
  </div>