import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme.service';
import { skill, themes } from '../skills-scroll.component';

@Component({
  selector: 'app-scroll-item',
  templateUrl: './scroll-item.component.html',
  styleUrls: ['./scroll-item.component.scss']
})
export class ScrollItemComponent implements OnInit {

  @Input() item: skill;
  @Input() defaultTheme$: Observable<themes>;

  @Output() nextSkillOutput: EventEmitter<number> = new EventEmitter();

  windowInnerHeight: number;
  itemColor: string;
  colorTheme = new BehaviorSubject<themes>("dark");

  constructor(private _themeService: ThemeService) { }

  ngOnInit() {
    this.windowInnerHeight = window.innerHeight-56;

    // TODO unsubscribe once destoryed
    this._themeService.skillItemTheme$.subscribe(theme =>  this.colorTheme.next(theme))
  }

  nextSkill() {
      this.nextSkillOutput.emit(1)
  }

  prevSkill() {
      this.nextSkillOutput.emit(-1)
  }
}
