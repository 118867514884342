import { Component, OnInit } from '@angular/core';
import { FormControl , FormGroup } from '@angular/forms';

@Component({
  selector: 'app-werk',
  templateUrl: './werk.component.html',
  styleUrls: ['./werk.component.scss']
})
export class WerkComponent implements OnInit {

  username:string;
  password:string;
  password_correct:boolean;
  data:any;

  login = new FormGroup({
    username_input: new FormControl(''),
    password_input: new FormControl(''),
  });
  

  constructor() { }

  headerTitle = "Werk"
  headerIntro = "Een kleine selectie interessante projecten, kort toegelicht"

  ngOnInit() { 
  }

  onSubmit() {
     
    

    this.data = this.login.value;
    console.warn(this.data);

     
      this.username = this.data.username_input
      this.password = this.data.password_input
 

      if(this.username === 'arjen' && this.password === 'Wohoo1!'){
        this.password_correct=true
      }else{
        this.password_correct=false
      }
       
     
  }

}
