<div class="abbo_r">
  <div class="container">
      <div class="row">
        <div class="col-lg-12">    <h4>{{title}}</h4></div>
          <div class="col-lg-6 video_foto-col">
          
              <p>De NS Keuzehulp applicatie was Arjens grootste project bij de NS. De applicatie helpt klanten van NS met het maken van de beste abonnementskeuze op basis van hun opgegeven reisgedrag of informatie uit hun reisverleden uit de Mijn NS applicatie.</p>
              <div class="video_container">
                  <video controls [muted]="true" width="100%">
                      <source src="../../../../assets/video/keuzehulp/NSFlexdesignsprint2019 compressed.mp4" type="video/mp4">
                  </video>
              </div>
            </div>
            <div class="col-lg-6 text-col">
              <p>Arjen heeft als Lead developer bij NS de nieuwe keuzehulp applicatie qua front-end voor zijn rekening genomen. De logica in de applicatie zat tevens voor 99% in de front-end. De externe informatiebronnen waren het Mijn NS account van de gebruiker en een databestand met alle informatie en prijzen per abonnement. Alle schermen, user-flows, en interactieve onderdelen zijn door Arjen ontwikkeld.
              </p>

                <h6>Gebruikte tools/technieken:</h6>
                <p>Javascript, Handlebars, Webpack, API's, Relay42, HTML, CSS. </p> 
                
            </div>
      </div>
  </div>
</div>