import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-logiqs",
  templateUrl: "./logiqs.component.html",
  styleUrls: ["./logiqs.component.scss"]
})
export class LogiqsComponent implements OnInit {
  title: string = "Front-end Architectuur & Training geven";

  constructor() {}

  ngOnInit() {}
}
