<div class="login__container">
  <h5 >Please log in</h5>
  <form #loginForm="ngForm">
    <div class="form-group"> 
      <input type="text" class="form-control" id="password" placeholder="Hint: huisnummer eerste woning"
       required
       [(ngModel)]="password" name="password"> 
    </div> 

    <button *ngIf="this.triedLoggingIn < 3" class="btn btn-success btn-succes-mod" (click)="login()">Log in</button>
    <h6 *ngIf="this.triedLoggingIn >= 3" class="alertMessage">You tried to many times</h6>
  </form>
</div>


<div   class="container">
  <div class="row" *ngIf="correctPassword">
    <div class="col">
      <h1>Sandbox</h1>
    </div>
  </div>

  <app-interact-with-apis-http-module *ngIf="correctPassword"> </app-interact-with-apis-http-module>

  <app-reactive-form *ngIf="correctPassword"></app-reactive-form>

  <app-sharing-data *ngIf="correctPassword"></app-sharing-data>

  <app-template-driven-form *ngIf="correctPassword">  </app-template-driven-form>
  

  <div class="row pokerow" *ngIf="correctPassword">
    <h2>Lets see some cool pokemon!</h2>
    <div class="col ">
      <div>
        <button mat-raised-button (click)="giveRandomPokemon()">
          Give random Pokemon
        </button>
      </div>
      <br />
      <div>
        
        <br />
        <button
          mat-raised-button
          (click)="givePokenmonNumber(this.chosenNumber)"
        >
          Give Pokemon #:
          <input class="inputInButton" [(ngModel)]="chosenNumber" />
        </button>
      </div>
    </div>
    <div class="col">
      <div>
        <img src="{{ this.pokemonImage }}" alt="" />
      </div>
    </div>
  </div>
</div>
