import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { themes } from '../components/skills-scroll/skills-scroll.component';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() { }

  skillItemTheme$ = new BehaviorSubject<themes>("regular");

}
