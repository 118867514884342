import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Event } from '@angular/router';



@Component({
  selector: 'app-add-todo',
  templateUrl: './add-todo.component.html',
  styleUrls: ['./add-todo.component.scss']
})
export class AddTodoComponent implements OnInit {
  @Output() addTodo: EventEmitter<any> = new EventEmitter();

  title:string;

  constructor() { }

  ngOnInit() {
  }

  onSubmit(){
    const todo = {
      title: this.title,
      completed:false
    }
    this.addTodo.emit(todo);
  }
  

}
