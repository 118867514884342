import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-peterconnects',
  templateUrl: './peterconnects.component.html',
  styleUrls: ['./peterconnects.component.scss']
})
export class PeterconnectsComponent implements OnInit {

  title: string = "Full stack developer bij PeterConnects & Anywhere365";

  constructor() { }

  ngOnInit() {
  }

}
