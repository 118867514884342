<div class="multi_m">
  <div class="container">
      
      <div class="row">
          <div class="col-lg-12"><h4>{{title}}</h4></div>
          <div class="col-lg-6 video_foto-col">
              
              <p>In de NS Reisplanner App kon geen kaartje aangeboden worden zodra het begin- of eindpunt geen treinstation was. Lees hieronder hoe Arjen dit mogelijk heeft gemaakt.</p>
              <div class="video_container">
                  <video controls [muted]="true" width="100%">
                      <source src="../../../../assets/video/mutedmulti.mp4" type="video/mp4">
                    </video>
              </div>
            </div>
            <div class="col-lg-6 text-col">
              <p>Door de NS Reisplanner API aan te roepen kon Arjen alle mogelijke stations in het reisadvies extraheren. 
                Met deze stations kon zijn script een begin- en een eindstation vaststellen (mits deze voldeden aan een aantal voorwaarden). 
                Met het begin en het eindstation kon de prijzen-API aangeroepen worden om de nieuwe prijs in het UI te tonen. 
                En als laatste werd de input voor de saleswizard klaargezet voor de gebruiker zodat zij het kaartje ook daadwerkelijk konden kopen 
                zodra ze op 'Koop treinkaartje' klikte.</p>

                <h6>Gebruikte tools/technieken:</h6>
                <p>Javascript, Jquery, API's, Relay42, HTML, CSS</p>

                
                
            </div>
      </div>
  </div>
</div>