<div class="abbo_r">
    <div class="container">
        <div class="row">
            <div class="col-lg-12"><h4>{{title}}</h4></div>
            <div class="col-lg-6 video_foto-col col--img"> 
                <h6>Originele kaartverkooppagina</h6>
                <img src="../../../../assets/werk_images/reisplanner_op_verkoop/original.png" alt="Original in experiment"  class="devar_img first_image">  

                <p>Front-end Javascript Injectie op de ticketverkoop pagina van NS. Lees hieronder hoe dit experiment tot productie kwam.</p>
                <p>Deze front-end is gebouwd naar aanleiding van een a/b test waarin de hypothese alsvolgt luide: Door de reisplanner met salezwizard integratie te implementerne op de ticketverkooppagina zal de conversie stijgen.
                    In de Orginele situatie moest men eerst een type kaartje selecteren en kon men vervolgens in een oude bestel funnel de reis plannen. 
                    Met de nieuwe sales-wizard achter de reisplanner kon de gebruiker een reis plannen en tegelijkertijd het juiste type kaartje hierbij selecteren. 
                    Arjen heeft hier de front-end voor gebouwd en vervolgens heeft het experiment significante resultaten geboekt en staat de reisplanner met saleswizard integratie live op de website van NS.
                  </p>
                <div class="video_container">
                  
                  
                  
                    
                </div>
              </div>
              <div class="col-lg-6 text-col col--img">
                  <h6>Variant homepage (Arjens Front-end)</h6>
                  <img src="../../../../assets/werk_images/reisplanner_op_verkoop/var A my frontend.png" alt="variant in experiment"  class="devar_img">
                
                <br>
                <br>
                  <h6>Gebruikte tools/technieken:</h6>
                  <p>Javascript, Jquery, API's, Relay42, HTML, CSS, VS Code, Google Analytics, Optimizely </p>
                  
                  
              </div>
        </div>
    </div>
  </div>