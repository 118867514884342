import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-suggestion-autocomplete',
  templateUrl: './input-suggestion-autocomplete.component.html',
  styleUrls: ['./input-suggestion-autocomplete.component.scss']
})
export class InputSuggestionAutocompleteComponent implements OnInit {


  title:string='Input suggesties en autocomplete Reisplanner NS';

  constructor() { }

  ngOnInit() {
  }

}
