import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";

import { HomeComponent } from "./components/home/home.component";

import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { ValidateService } from "../services/validate.service";
// import { AuthService } from "../services/auth.service"; // AuthService, // Do not delete before you get this working again

import { HttpClientModule } from "@angular/common/http";

// import { AuthGuard } from "./guards/auth.guard";
import { SkillsComponent } from "./components/skills/skills.component";
import { Skills2Component } from "./components/skills2/skills2.component";
import { WerkComponent } from "./components/werk/werk.component";

import { WerkervaringComponent } from "./components/werkervaring/werkervaring.component";
import { ContactComponent } from "./components/contact/contact.component";

import { TodosComponent } from "./components/todos/todos.component";
import { TodoItemComponent } from "./components/todo-item/todo-item.component";
import { AddTodoComponent } from "./components/add-todo/add-todo.component";
import { MultimodaleReisComponent } from "./components/werk/multimodale-reis/multimodale-reis.component";
import { AbboRecommenderComponent } from "./components/werk/abbo-recommender/abbo-recommender.component";
import { HeaderComponent } from "./components/header/header.component";
import { HeroSwitchComponent } from "./components/werk/hero-switch/hero-switch.component";
import { ReisplannerLosseKaartjesComponent } from "./components/werk/reisplanner-losse-kaartjes/reisplanner-losse-kaartjes.component";
import { InputSuggestionAutocompleteComponent } from "./components/werk/input-suggestion-autocomplete/input-suggestion-autocomplete.component";

import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";

import { SkillItemComponent } from "./components/skills2/skill-item/skill-item.component";
import { SandboxComponent } from "./sandbox/sandbox.component";

import { MaterialModule } from "../app/sandbox/sandbox.component";
import { GetPokemonService } from "./get-pokemon.service";
import { TemplateDrivenFormComponent } from "./sandbox/sandbox-components/template-driven-form/template-driven-form.component";
import { SharingDataComponent } from "./sandbox/sandbox-components/sharing-data/sharing-data.component";
import { ParentComponent } from "./sandbox/sandbox-components/sharing-data/parent/parent.component";
import { ChildComponent } from "./sandbox/sandbox-components/sharing-data/child/child.component";
import { ReactiveFormComponent } from "./sandbox/sandbox-components/reactive-form/reactive-form/reactive-form.component";
import { InteractWithApisHttpModuleComponent } from "./sandbox/sandbox-components/interactWithApisHttpModule/interact-with-apis-http-module/interact-with-apis-http-module.component";
import { KeuzehulpComponent } from "./components/werk/keuzehulp/keuzehulp.component";
import { LogiqsComponent } from "./components/werk/logiqs/logiqs.component";
import { RockstarsCvToolComponent } from './components/werk/rockstars-cv-tool/rockstars-cv-tool.component';
import { PeterconnectsComponent } from './components/werk/peterconnects/peterconnects.component';
import { SkillsScrollComponent } from './components/skills-scroll/skills-scroll.component';
import { ScrollItemComponent } from './components/skills-scroll/scroll-item/scroll-item.component';
import { ColorThemeSwitcherComponent } from './components/skills-scroll/scroll-item/color-theme-switcher/color-theme-switcher.component';
import { WerkervaringOverzichtComponent } from './components/werkervaring-overzicht/werkervaring-overzicht.component';
import { OverviewTileComponent } from './components/general-components/overview-tile/overview-tile.component';

export function tokenGetter() {
  return localStorage.getItem("id_token");
}

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "skills", component: SkillsComponent },
  { path: "skills2", component: Skills2Component },
  { path: "skills-scroll", component: SkillsScrollComponent },
  { path: "sandbox", component: SandboxComponent },
  { path: "werkervaring", component: WerkervaringComponent },
  { path: "werkervaring-overzicht", component: WerkervaringOverzichtComponent },
  { path: "contact", component: ContactComponent },
  { path: "todos", component: TodosComponent },
  { path: "werk", component: WerkComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    SkillsComponent,
    Skills2Component,
    WerkComponent,
    WerkervaringComponent,
    ContactComponent,
    TodosComponent,
    TodoItemComponent,
    AddTodoComponent,
    MultimodaleReisComponent,
    AbboRecommenderComponent,
    HeaderComponent,
    HeroSwitchComponent,
    ReisplannerLosseKaartjesComponent,
    InputSuggestionAutocompleteComponent,
    SkillItemComponent,
    SandboxComponent,
    TemplateDrivenFormComponent,
    SharingDataComponent,
    ParentComponent,
    ChildComponent,
    ReactiveFormComponent,
    InteractWithApisHttpModuleComponent,
    KeuzehulpComponent,
    LogiqsComponent,
    RockstarsCvToolComponent,
    PeterconnectsComponent,
    SkillsScrollComponent,
    ScrollItemComponent,
    ColorThemeSwitcherComponent,
    WerkervaringOverzichtComponent,
    OverviewTileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes, { useHash: true, anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' }),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MaterialModule
  ],
  providers: [ValidateService,
    // AuthService, // Do not delete before you get this working again
    //  AuthGuard,
     GetPokemonService],
  bootstrap: [AppComponent]
})
export class AppModule {}
