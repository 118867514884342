import { Component, OnInit } from '@angular/core';
import { GetNavHeightService } from '../../services/get-nav-height.service';
import { reduce } from 'rxjs/operators';


import { ReactiveFormsModule } from '@angular/forms';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  windowHeight:number = window.innerHeight;
  navHeight:number;
  outerStyles:object;

  showDetails = false;

  constructor(private navVariable:GetNavHeightService) { }

  ngOnInit() {
    //subscribe to the dynamic nav variable
    this.navVariable.currentVariable.subscribe(data => this.navHeight = data);


    this.outerStyles = {
      'min-height': `${this.windowHeight - this.navHeight}px`
    }
    //alert(`${this.windowHeight - this.navHeight}`)
  }




}
