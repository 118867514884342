import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-switch',
  templateUrl: './hero-switch.component.html',
  styleUrls: ['./hero-switch.component.scss']
})
export class HeroSwitchComponent implements OnInit {

  title:string='Front-end Homepage NS + Data script';

  constructor() { }

  ngOnInit() {
  }

}
