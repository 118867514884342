<div class="outer">
  <app-header
    [headerTitle]="headerTitle"
    [headerIntro]="headerIntro"
  ></app-header>
  <div class="container">
    <div class="row">
      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-peterconnects></app-peterconnects>
        </div>
      </div>
      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-logiqs></app-logiqs>
        </div>
      </div>

      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-rockstars-cv-tool></app-rockstars-cv-tool>
        </div>
      </div>
      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-keuzehulp></app-keuzehulp>
        </div>
      </div>
      <div *ngIf="true" class="  col-lg-12 work_item ">
        <div class="work_item_container">
          <app-abbo-recommender></app-abbo-recommender>
        </div>
      </div>
      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-multimodale-reis></app-multimodale-reis>
        </div>
      </div>

      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-hero-switch></app-hero-switch>
        </div>
      </div>
      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-input-suggestion-autocomplete></app-input-suggestion-autocomplete>
        </div>
      </div>
      <div class="  col-lg-12 work_item">
        <div class="work_item_container">
          <app-reisplanner-losse-kaartjes></app-reisplanner-losse-kaartjes>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="false">
      <div class="form_c">
        <h6>Log in om speciale projecten te bekijken</h6>
        <br />
        <form [formGroup]="login" (ngSubmit)="onSubmit()">
          <label>
            Username:
            <input
              type="text"
              class="form-control"
              formControlName="username_input"
            />
          </label>
          <br />
          <label>
            Password:
            <input
              type="text"
              class="form-control"
              formControlName="password_input"
            />
          </label>
          <br />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
    <!-- end row -->
  </div>
</div>
