import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject"; 

@Injectable({
  providedIn: 'root'
})
export class GetNavHeightService {

  private navHeightVariable = new BehaviorSubject<number>(11);
  currentVariable = this.navHeightVariable.asObservable();

  constructor() { }

  changeVariable(variable:number){
    this.navHeightVariable.next(variable)

  }
}
