import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-rockstars-cv-tool",
  templateUrl: "./rockstars-cv-tool.component.html",
  styleUrls: ["./rockstars-cv-tool.component.scss"]
})
export class RockstarsCvToolComponent implements OnInit {
  title: string = "Front-end CV Tool Rockstars";

  constructor() {}

  ngOnInit() {}
}
