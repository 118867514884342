<div class="container">
  <div class="row">
    <div class="col">
      <h1>skills</h1>    
      skillName <input type="text" [(ngModel)]="skillName" (ngModelChange)="onChange($event)"> : {{skillName}}
      <br>
      skillYears <input type="text" [(ngModel)]="skillYears" (ngModelChange)="onChange($event)">
      <br>
      skillLevel <input type="text" [(ngModel)]="skillLevel" (ngModelChange)="onChange($event)">
      <br>
      skillLove <input type="text" [(ngModel)]="skillLove" (ngModelChange)="onChange($event)">
      <br>
      <br>
      <button (click)="onAddSkill()">add skill</button>
      <br>


      <article>
        this is the service value of the form: {{inputBron | json}}
          <button (click)="newInput()">log new input via service</button>
      </article>
      
      

      <br>
      <br>

      <app-skill-item  *ngFor="let skill of skillsArray" [skillObject]="skill" [newSkill]="newSkill" [skillName]="skillName"> 
        <!-- skillname: {{skill.skillName}}
        skillyears: {{skill.skillyears}}
        skillLevel: {{skill.skillLevel}}
        skillLove: {{skill.skillLove}} -->

        

      </app-skill-item>

    </div>
  </div>
</div>