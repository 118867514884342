import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-werkervaring',
  templateUrl: './werkervaring.component.html',
  styleUrls: ['./werkervaring.component.scss']
})
export class WerkervaringComponent implements OnInit {

  headerTitle = "Werkervaring";
  headerIntro = "Arjens relevante werkverleden";

  constructor() { }

  ngOnInit() {
  }

}
