<div class="panel panel--child">
    <h4>Child component</h4>
    <p>Has this parent-data: {{this.someData}}</p>

    <form action="">
      <label for="Add your message in child"></label>
      <input class="form-control" type="text" #dummy>
      <button class="btn btn-primary" (click)="addNewItem(dummy.value)">Verzend</button>
      <button class="btn btn-secondary" (click)="newMessageToService(dummy.value)">Verzend naar service</button>
    </form>

  </div>