<div>
  <h4>
    Reactive Forms
  </h4>
  


    
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="form__container">
      <mat-form-field> 
        <mat-label>Email:</mat-label>
        <input type="text" matInput formControlName="email" placeholder="type your email">
        <mat-error *ngIf="profileForm.controls.email.invalid && (profileForm.controls.email.dirty || profileForm.controls.email.touched )"
            class="">

          <span *ngIf="profileForm.controls.email.errors.required">
            email is required.
          </span>
          <span *ngIf="profileForm.controls.email.errors.minlength">
            email must be at least 4 characters long.
          </span>
          <span *ngIf="profileForm.controls.email.errors.minlength">
              email must be at least 4 characters long.
          </span>
          <span *ngIf="profileForm.controls.email.errors.email">
              You must enter a valid email
          </span>
        </mat-error>
        <mat-hint >Dit moet een echt email zijn</mat-hint>
        <!-- <mat-hint *ngIf="!email.valid">Dit moet een echt email zijn</mat-hint> -->
      </mat-form-field> 
      <mat-form-field> 
        <mat-label>First name:</mat-label>
        <input type="text" matInput formControlName="firstName" placeholder="type your first name">
        <mat-error *ngIf="profileForm.controls.firstName.invalid && (profileForm.controls.firstName.dirty || profileForm.controls.firstName.touched )"
            class="">
          <span *ngIf="profileForm.controls.firstName.errors.required">
              firstName is required.
          </span>
          <span *ngIf="profileForm.controls.firstName.errors.minlength">
              firstName must be at least 1 characters long.
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field> 
        <mat-label>Last name:</mat-label>
        <input type="text" matInput formControlName="lastName" placeholder="type your last name">
        <mat-error *ngIf="profileForm.controls.lastName.invalid && (profileForm.controls.lastName.dirty || profileForm.controls.lastName.touched )"
            class="">
          <span *ngIf="profileForm.controls.lastName.errors.required">
              lastName is required.
          </span>
          <span *ngIf="profileForm.controls.lastName.errors.minlength">
              lastName must be at least 1 characters long.
          </span>
        </mat-error>
      </mat-form-field>
      
      <button  mat-raised-button type="submit" [disabled]="!profileForm.valid">Submit</button>
    </div>
  </form>


    <br>
    <br>
  <button (click)="updateName()">nance me</button>
  
  
</div>