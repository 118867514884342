import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MyDataServiceService {

  constructor(private httpService:HttpClient) { }

  url:string='https://jsonplaceholder.typicode.com/users';
  reqresUrl:string="https://reqres.in/";



  httpOptions = {
    headers: new HttpHeaders({
      "Content-type": "application/json; "
    })
  };

  getUsers(){
    return this.httpService.get(this.url);
  }

  updateUser():Observable<any>{
    return this.httpService.put('https://jsonplaceholder.typicode.com/posts/1', 'abc' , this.httpOptions)
    .pipe(
      // catchError(this.handleError('updateHero', hero))
    );
  }

  getResReqUsers(){
    return this.httpService.get(this.reqresUrl+'api/users/2')
  }

  createResReqUser(val){
    console.log(val)
    return this.httpService.post(this.reqresUrl+'api/users', val  )
      .pipe(
      // catchError(this.handleError('updateHero', hero))
      );
 
  }

  updateResReqUser(val){
    console.log(val)
    return this.httpService.put(this.reqresUrl+'api/users/2', val )
      .pipe(
      // catchError(this.handleError('updateHero', hero))
      );
  }

  
}
