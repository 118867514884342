<div class="outer werkervaring-background">
  <app-header
    [headerTitle]="headerTitle"
    [headerIntro]="headerIntro"
  ></app-header>
  <div class="container ">
    <div class="row">
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel rockstars">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/priva.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2021-heden</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong >Front end Developer</strong>  <br />
              Arjen werkt voor deze opdracht aan de nieuwe cloud applicatie van <a href='https://www.youtube.com/watch?v=tlCQm0rEfDc&t=7s&ab_channel=PrivaAll' target="_blanc"> Priva Opertor (Horticulture) </a>. Als freelance front-end developer draagt Arjen bij aan de doorontwikkeling van de cloud based applicatie met een Angular Front-end.

            </p>
          </div>
        </div>
      </div>
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel rockstars">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/peco-any.jpg" />
            </div>
            <div class="companyName">
              <h5 class="company">2020-2021</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong >Fullstack Developer</strong>  <br />
              Fullstack development aan een complexe<a href='https://www.youtube.com/watch?v=nehx2tYuJSU&ab_channel=PeterConnects' target="_blanc"> receptionist applicatie</a> inclusief integraties met bijvoorbeeld Webex of Microsoft Teams.
              Voornamelijk op de Front-end maar ook C# back-end.
            </p>
          </div>
        </div>
      </div>
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel rockstars">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/logos/logiqs.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2020</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong>Front-end Architect & Front-end Trainer</strong> (via
              Rockstars) <br />
              Bij Logiqs heef Arjen het bedrijf geadviseerd in het maken van de
              juiste keuze voor het Front-end Framework. Hier heeft Arjen de
              titel Front-end Architect mogen dragen en daarnaast heeft hij ook
              Angular training gegeven nadat hij de applicatie architectuur had
              opgeleverd.
            </p>
          </div>
        </div>
      </div>
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel rockstars">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/logos/Redkiwi-logo.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2020</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong>Front-end Developer</strong> (via Rockstars) <br />
              Front-end development bij Redkiwi was een project via Team
              Rockstars IT. Het was erg leuk om eens in de PHP wereld te kijken.
            </p>
          </div>
        </div>
      </div>
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel rockstars">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/logos/rockstars_logo.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2019</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong>Front-end Developer</strong> <br />
              Arjen werkt sinds 2019 bij Team Rockstars IT. Hij werkt daar als
              Full stack Developer met de focus op de Front-end en biedt als
              consultant zijn expertise aan de klanten van Team Rockstars IT. De
              klanten waar hij voornamelijk te vinden zal zijn, zijn de grotere
              corperates. In 2020 overweegt Arjen ook om voor kleinere bedrijven
              of start-ups te werken als consultant. Een complete Javascript
              Stack is wat Arjen de klant bied. NodeJS + Express op de back-end,
              Angular en vanilla Javascript op de front-end en MongoDB +
              Mongoose in de database (noSQL). Al deze frameworks en databases
              zijn te bedienen met Javascript & Typescript, aldus Arjen's
              expertise.
            </p>
          </div>
        </div>
      </div>
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel NS">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img id="NS" src="../../../assets/NS.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2018-2019</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong>Lead Front-end Developer</strong> (via CGI) <br />
              Werkzaam als (Lead) Front-end developer (consultant) in het Agile
              Conversie Optimalisatie Team bij de Nederlandse Spoorwegen (NS).
              Arjen werken samen met zijn team aan conversie optimalisatie
              experimenten welke hij middels Javascript(clientside) of
              serverside(Angular2+) Injecteerde. Zijn front-ends waren vanaf dat
              punt live te zien op de website van NS en na enige tijd data
              verzamelen kon er uitslag gegeven worden over de resultaten. Arjen
              bediende verschillende bouw-teams bij de NS. Hier was het deels
              zijn rol om data-driven werken te promoten binnen de bouwteams en
              daarnaast bood hij zijn Optimizely of A/b testing expertise aan
              Angular-teams.
            </p>
          </div>
        </div>
      </div>
      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel cgi">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/CGI-logo-.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2017-2019</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              <strong>Front-end Developer (detachering)</strong> <br />
              Als software engineer bij CGI Nederland werk(te) Arjen aan diverse
              opdrachten die zowel intern als extern plaatsvonden. Intern is hij
              met de website van CGI aan de slag geweest. Hierin ondersteunde
              hij de verhuizing van de oude website naar de nieuwe. Zijn
              activiteiten waren naast het opbouwen van de pagina's erg divers.
              Hij sprak en ondersteunde collega's over de hele wereld (Canada,
              India, Filepijnen, Engeland etc. ) en samen werkte ze aan de
              nieuwe website. Zijn externe werkzaamheden (buiten CGI's
              hoofdkantoor) heeft hij onder andere gedaan voor ING Bank en de
              Nederlandse Spoorwegen (NS). Bij ING heeft hij samen met het team
              een zakelijke vastgoed applicatie ontworpen (UX/UI). Hierin was
              zijn rol voornamelijk de flow binnen de applicatie vaststellen en
              deze onderbouwen met designs. De duur van het project was 2
              maanden. Bij de Nederlandse Spoorwegen (NS) was hij onderdeel van
              het Conversie Optimalisatie Team. Zijn team stelde hypotheses op
              over alle online platforms van NS en hoe deze verbeterd konden
              worden, maakte hier designs van en vervolgens was het aan de
              Front-end Developers (Arjens rol) om deze designs te ontwikkelen
              met Javascript, Jquery, Angular2+, HTML en CSS. Door de scripts te
              injecteren in de online platforms kon er getest worden of de
              hypotheses (significante) waarde toevoegde. Gewerkt met tools
              zoals Optimizely, Relay, Usabilla, Visual Studio Code, Webpack en
              Trello.
            </p>
          </div>
        </div>
      </div>

      <div class="  col-lg-12 panel">
        <div class="Werkervaring_panel Freelance">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              Freelance
            </div>
            <div class="companyName">
              <h5 class="company">2016-Heden</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              Holleman Websolutions was een onderneming gericht op online
              diensten en technologieën. Bij Holleman Websolutions konden
              klanten allerlei verschillende services en diensten afnemen
              waaronder Webdesign, Appdesign, Webdevelopment, Webhosting en
              Graphic-design en andere online services zoals SEO en Social
              Media. Arjen verzorgde graag het gehele proces voor zijn klanten,
              zodat zij geen zorgen hebben aan onderdelen zoals webhosting of
              domeinen. Holleman Websolutions stond voor persoonlijk en
              laagdrempelig contact, kwaliteit en echte aandacht voor de klant.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="false" class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="Werkervaring_panel Studio">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/studio_projectie.png" />
            </div>
            <div class="companyName">
              <h5 class="company">2017</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              Voor zijn afstudeerstage mocht Arjen bij Studio Projectie in
              Leiden onderzoek doen naar hoe een nieuw laagdrempelig online
              analytisch dashboard ontworpen moest worden. Vervolgens zal hij
              dit dashboard ook ontwerpen en zo ver mogelijk ontwikkelen tijdens
              zijn stageperiode. Dit alles is uiteraard in samenwerking met
              betrokken collega's binnen Studio Projectie. Een erg leuk project
              met een erg leuk team! Het resultaat: de Bachelor of Science.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="Werkervaring_panel student">
          <div class="Werkervaring_panel_header">
            <div class="companyLogo">
              <img src="../../../assets/student.jpg" />
            </div>
            <div class="companyName">
              <h5 class="company">2016-2017</h5>
            </div>
          </div>
          <div class="Werkervaring_panel_body">
            <p>
              Bij Studentaanhuis hielp ik klanten met uiteenlopende
              computer/website problemen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
