import { Component, OnInit } from '@angular/core';
import { SharingDataServiceService } from "../sharing-data-service.service";

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent implements OnInit {

  message:string;

  constructor(private data: SharingDataServiceService) { }

  someData:any;
  arrayOfStrings:Array<string>=['nulwaarde']

  ngOnInit() {
    this.someData = 'The parent data';

    this.data.currentMessage.subscribe(value => this.message = value)
  }

  absorbItem(value:string){
    this.arrayOfStrings.push(value)
  }

}
