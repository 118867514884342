import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-driven-form',
  templateUrl: './template-driven-form.component.html',
  styleUrls: ['./template-driven-form.component.scss']
})
export class TemplateDrivenFormComponent implements OnInit {

  constructor() { }

  textName:string;
  textDomein:string;
  textAap:string;
  clickedOnButton:boolean;

  ideeArray:Array<any>=[];

  ngOnInit() {
  }

  submitter(){
    this.ideeArray.push([this.textName,this.textDomein,this.textAap])
  }

  invalidKnop(){
    console.warn('clicked')
    this.clickedOnButton = true;
  }

}
