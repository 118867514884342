<div class="panel">
    <h4>Parent component</h4>
    <p>Has this data: {{this.someData}}</p>
    <p>It also has the shared data from the service: {{message}}</p> 

    <ul>
      <li *ngFor="let item of arrayOfStrings">{{item}}</li>
    </ul>

    <div class="child__container">
        <app-child class="" (childEventEmitter)=absorbItem($event) [someData]="this.someData"></app-child>
    </div>
  
  </div>

