import { Component, OnInit } from '@angular/core'; 
import { FormControl , FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-reactive-form',
  templateUrl: './reactive-form.component.html',
  styleUrls: ['./reactive-form.component.scss']
})
export class ReactiveFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

  profileForm = new FormGroup({
    firstName: new FormControl(null , [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
    lastName: new FormControl(null , [Validators.minLength(1), Validators.maxLength(50)]),
    email: new FormControl(null , [Validators.email,Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
  }); 

  updateName() {
    // this.profileForm.firstName.setValue('Nancy');
    this.profileForm.patchValue({
      firstName: 'nance', 
      // formControlName2: myValue2 (can be omitted)
    });
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }

}
