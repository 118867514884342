import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-color-theme-switcher',
  templateUrl: './color-theme-switcher.component.html',
  styleUrls: ['./color-theme-switcher.component.scss']
})
export class ColorThemeSwitcherComponent implements OnInit {

  @Output() switchTheme: EventEmitter<any> = new EventEmitter();

  // Add light again later once you've got NGRX installed for the menu to go black if you click the white theme
  colorThemes = ["regular","dark",];

  constructor(private _themeService: ThemeService) { }

  ngOnInit() {
  }

  switchColorTheme(colorTheme) {

    this._themeService.skillItemTheme$.next(colorTheme)

  }

}
