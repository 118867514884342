<div class="abbo_r">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4>{{ title }}</h4>
      </div>
      <div class="col-lg-6 video_foto-col">
        <p></p>
        <div class="video_container">
          <img
            class="img-werk"
            src="../../../../assets/peco-any.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="col-lg-6 text-col">
        <p>
          Fullstack development aan een complexe receptionist applicatie inclusief integraties met bijvoorbeeld Webex of Microsoft Teams.
          Voornamelijk op de Front-end maar ook C# back-end.

        </p>

        <h6>Gebruikte tools/technieken:</h6>
        <p>
          Angular, RXJS, Javascript, C#, .NET, MVC, Less, HTML, SQL-Database, Jasmine, Karma, Jenkins, TestComplete and more.
        </p>
      </div>
    </div>
  </div>
</div>
