<div class="outer" [ngStyle]="outerStyles">

    <div class="container outer_container home_container" >

      <div class="row">
        <div class="col-sm foto_col ">

          <div class="image_container_medium">
              <img src= "assets/me.jpg">


          </div>

        </div>
        <div class="col-md-8 text_col">
           <div class="header_blok">
              <h1>Arjen Holleman</h1>
              <h5>{{headerTitle}}</h5>
              <h6 class="subheader_home">
                {{headerIntro}}
              </h6>
           </div>
           <div class="text_blok">
             <p>
                Als Full-stack developer werkt Arjen het liefst met Frameworks zoals Angular of React.
                Hij is een online all-rounder: Front-end, Back-end, SEO, Google Analytics, CMS, Interaction Design, Webdesign.. Waar je Arjen écht gelukkig mee maakt is Front-end Development.

                Arjen's kracht ligt in het ontzorgen van de klant of het team.
                Dankzij een groot gevoel van verantwoordelijkheid zal Arjen projecten naar zich toe trekken en zeker aan de bel trekken zodra er iets niet lekker loopt of beter kan.
                Dit ontzorgt de opdrachtgever en andere collega's.

             </p>
           </div>
        </div>
      </div>
    </div>
</div>
