<div class="row" >
  <div class="col">
    <h4>Templat driven form</h4>
    <span>met validatie</span>

    <form #templateForm="ngForm" (ngSubmit)="submitter()">
      <div class="form-group"> 

        <br>
        <h4>Name</h4>
          <input id="name" name="name" class="form-control"
          required minlength="4" 
          [(ngModel)]="textName" #name="ngModel" >
    
    <div *ngIf="name.invalid && (name.dirty || name.touched)"
        class="alert alert-danger">
    
      <div *ngIf="name.errors.required">
        Name is required.
      </div>
      <div *ngIf="name.errors.minlength">
        Name must be at least 4 characters long.
      </div> 
    
    </div>

    <br>
    <h4>Beschrijf idee</h4>

    <input 
    id="aap" 
    name="aap" 
    class="form-control"
    required minlength="4" 
    [(ngModel)]="textAap" #aap="ngModel"
    placeholder="welke aap?"
     >

    <div *ngIf="aap.invalid && (aap.dirty || aap.touched)"
      class="alert alert-danger">

      <div *ngIf="aap.errors.required">
          beschrijving is required.
      </div>
      <div *ngIf="aap.errors.minlength">
          beschrijving must be at least 4 characters long.
      </div> 

    </div>  

    <br> 
    <h4>Domein</h4>
    <input 
      id="domein"   
      name="domein" 
      class="form-control" 
      required minlength="4" 
      [(ngModel)]="textDomein" #domein="ngModel" 
      placeholder="voor welk domein?"      
    > 
    <div  *ngIf="domein.invalid && (domein.dirty || domein.touched)"
    class="alert alert-danger"> 
      <div *ngIf="domein.errors.required">
        Domein is verplicht
      </div>
      <div *ngIf="domein.errors.minlength">
          Domein must be at least 4 characters long.
      </div> 
    </div> 

    

    </div>

  

      <div  >
          <button (click)="invalidKnop()" class="btn btn-secondary" *ngIf="!templateForm.valid" type="submit">Controleer het formulier</button>
          <button class="btn btn-success btn-succes-mod" *ngIf="templateForm.valid" type="submit">Verstuur</button>
          <span  *ngIf="clickedOnButton && !templateForm.valid"
          class="alert alert-danger hintbox"> Hint: Vul het formulier (verder) in</span>
      </div>
      
    </form>
      <br>
      <br>
      <br>
      <article>
        <h5>Mijn ideeën</h5>
        <div *ngFor="let ideeitem of ideeArray" class="idee">          
            <h4>Idee: {{ideeitem[0]}} </h4><br> 
              <p>Beschrijving: {{ideeitem[1]}}</p>
              <p>Domein: {{ideeitem[2]}}</p>
        </div>

      </article>


  </div>
</div>