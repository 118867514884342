import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keuzehulp',
  templateUrl: './keuzehulp.component.html',
  styleUrls: ['./keuzehulp.component.scss']
})
export class KeuzehulpComponent implements OnInit {

  title:string='NS Keuze-hulp applicatie';

  constructor() { }

  ngOnInit() {
  }

}
