<div (click)="clickOnSkillArticle()">
    <article>
  
        <h5 >{{item_skillName}}</h5>
        <p>Jaren ervaring: {{item_skillYears}}</p>
        <p>Skill: {{item_skillLevel}}</p>
        <p>Love working with this skill: {{item_skillLove}}</p> 
      
      </article>

      <button (click)="clickOnSkillArticle()">update this item</button>
      
               
</div>


 