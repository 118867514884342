import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { Todo } from '../models/todo';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  todosUrl:string='https://jsonplaceholder.typicode.com/todos';

  todosLimit:string = '?_limit=5'

  constructor(private http:HttpClient) { }

  //get todos
  getTodos():Observable<Todo[]>{
   return this.http.get<Todo[]>(`${this.todosUrl}${this.todosLimit}`)
  }

  //toggle completed
  toggleCompleted (todo:Todo):Observable<any>{
    const url = `${this.todosUrl}/${todo.id}`;
    return this.http.put(url, todo, httpOptions);
  }

  //delete todo
  deleteTodo(todo:Todo):Observable<Todo>{
    const url = `${this.todosUrl}/${todo.id}`;
    return this.http.delete<Todo>(url, httpOptions);
  }

  //add todo
  addTodo(todo:Todo):Observable<Todo>{
  return this.http.post<Todo>(this.todosUrl, todo, httpOptions);
  }


  getRealTodos(){
    return  [
      {
        id:1,
        title:'Werk page!',
        completed:false
      },
      {
        id:2,
        title:'Contact page',
        completed:false
      },
      {
        id:3,
        title:'Style upgrade',
        completed:true
      },
      {
        id:4,
        title:'Animaties upgrade',
        completed:false
      },
      {
        id:5,
        title:'Next item',
        completed:false
      }
      

    ]
    

  }
}
