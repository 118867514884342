import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reisplanner-losse-kaartjes',
  templateUrl: './reisplanner-losse-kaartjes.component.html',
  styleUrls: ['./reisplanner-losse-kaartjes.component.scss']
})
export class ReisplannerLosseKaartjesComponent implements OnInit {

  title:string='Reisplanner implementeren op de treinkaart verkooppagina';

  constructor() { }

  ngOnInit() {
  }

}
