import { Component, OnInit, Input } from '@angular/core';
import { GetInputFromSkillFormService } from "../../../services/get-input-from-skill-form.service";

@Component({
  selector: 'app-skill-item',
  templateUrl: './skill-item.component.html',
  styleUrls: ['./skill-item.component.scss']
})
export class SkillItemComponent implements OnInit {

  @Input() skillObject: object;
  @Input() newSkill:object;
  @Input() skillName:string;
  
  
  item_skillName:string;
  item_skillYears:string;
  item_skillLevel:String;
  item_skillLove:string;

  inputBron:any;
  accesObject:any;

  

  constructor(private data: GetInputFromSkillFormService) { }

  ngOnInit() {
    this.data.currentInput.subscribe(inputBron => this.inputBron = inputBron)

    this.item_skillName = this.inputBron.skillName;
    this.item_skillYears =  this.inputBron.skillYears;
    this.item_skillLevel =  this.inputBron.skillLevel;
    this.item_skillLove =  this.inputBron.skillLove;
    
  //  console.log(this.skillObject)
  //const obj = this.skillObject
  
  /* this.item_skillYears= ;
  this.item_skillLevel= ;
  this.item_skillLove= ; */

  }

  clickOnSkillArticle(){
    //console.log(this.newSkill)
    //console.log(this.skillName)

    /* this.skillObject.skillName =  */

    //console.log(this.newSkill)
    //console.log(this.skillObject)

    console.log(this.inputBron.skillName)

     
  this.item_skillName = this.inputBron.skillName;
  this.item_skillYears =  this.inputBron.skillYears;
  this.item_skillLevel =  this.inputBron.skillLevel;
  this.item_skillLove =  this.inputBron.skillLove;

     
  }

  

}
