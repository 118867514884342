import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject"; 

@Injectable({
  providedIn: 'root'
})
export class GetInputFromSkillFormService {

  private inputBron = new BehaviorSubject<object>({'dummy':'dummy'})
  currentInput = this.inputBron.asObservable();

  constructor() { }

  changeInput(currentInput:object){
    this.inputBron.next(currentInput)

    //console.warn(this.inputBron)
    //console.warn(this.inputBron)

  }

}
