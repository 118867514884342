<h4>Getting data with httpClient</h4>

<button mat-raised-button (click)="showUsers()">Get users</button>

<ul>
  <li *ngFor="let user of allUsers">
    {{ user.name }}
    <ul>
      <li>id: {{ user.id }}</li>
      <li>username: {{ user.username }}</li>
      <li>email: {{ user.email }}</li>
      <li>city: {{ user.address.city }}</li>
      <li>phone: {{ user.phone }}</li>
      <li>website: {{ user.website }}</li>
      <li>company : {{ user.company.name }}</li> 
    </ul>
  </li>
</ul>

<button mat-raised-button (click)="updateUserFn()">Update user</button>

<button mat-raised-button (click)="showUsersResReq()">show res req user</button>

<button mat-raised-button (click)="createResUser(this.dataObj)">create res req user</button>
<button mat-raised-button (click)="updateResUser(this.dataObj)">updt res req user</button>

<h6>res</h6>
<span>{{ myRes | json }}</span>
<!-- <ul>
  <li *ngFor="let resUser of resUsers">
    
  </li>
</ul> -->
<br>
<span *ngIf="this.resUsers">
  <br>
  {{resUsers.data.first_name  }}
  <br>
  {{resUsers.data.last_name  }}
  <br>
  {{resUsers.data.email | json }} 
</span>
