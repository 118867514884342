<div class="outer bg_skills" [ngStyle]="outerStyles">
        <app-header [headerTitle]="headerTitle" [headerIntro]="headerIntro" ></app-header>
    <div class="container ">





        <div class="row">
            <div class="skill_ col-sm-6 col-lg-4" *ngFor="let skill of skills">

                <div class="skillOuter  "> <h4 class="skillTitle"> {{ skill.skillName }}</h4>
                  <!-- <p class="mb-2">Jaren ervaring: {{ skill.yearsOfExp }}</p> -->
                  <br>

                  <div  class="progress_bar">
                      <div class="inner_progress_bar" [ngStyle]="{'width': dynamicStyles(skill.skillLevelTo5)+'%'}">
                        <span class="ervaring">Skill</span>
                      </div>
                  </div>
                  <!-- <div class="loveToWorkWith">
                    <span class="heart_span" [ngStyle]="{'width': widthOfHeart(skill.loveWorkingWithItTo5)+'px'}" > <img src="../../../assets/heart_5_.png" alt="heart" class="heart_img">  </span>
                  </div> -->
                </div>
            </div>
            </div>




      </div>
</div>
