import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multimodale-reis',
  templateUrl: './multimodale-reis.component.html',
  styleUrls: ['./multimodale-reis.component.scss']
})
export class MultimodaleReisComponent implements OnInit {

  
  title:string='Multimodale reizen aanbieden';

  constructor() { }

  ngOnInit() {


    //const video_multimodaal = document.getElementsByClassName('video_multimodaal');
    //video_multimodaal.muted:Boolean = true;
  }

}
