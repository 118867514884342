import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sharing-data',
  templateUrl: './sharing-data.component.html',
  styleUrls: ['./sharing-data.component.scss']
})
export class SharingDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
