import { Component, OnInit } from '@angular/core';
import { GetNavHeightService } from "../../services/get-nav-height.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  headerTitle = "Freelance Full-stack Developer";
  headerIntro = "Met focus op front-end, het team en de organisatie";

  windowHeight:number = window.innerHeight

  navHeightVariable:number;


  outerStyles:Object;


  constructor(private navHeight:GetNavHeightService) { }

  ngOnInit() {
     this.navHeight.currentVariable.subscribe(height => this.navHeightVariable = height);
     //alert( `${this.windowHeight - this.navHeightVariable }px`)
     this.outerStyles = {
      'min-height': `${this.windowHeight - this.navHeightVariable }px`
      }

  }



  getNavHeight(){
    this.navHeight.changeVariable(123);

  }

}
