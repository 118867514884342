<div class="abbo_r">
    <div class="container">
        <div class="row">
            
                <div class="col-lg-12"><h4>{{title}}</h4></div>
            <div class="col-lg-6 video_foto-col"> 
                <p>
                    Gebruikersgemak verbeteren middels javascript en API's. Lees hieronder hoe dat voor dit experiment verliep.
                </p>
                <div class="video_container">
                    <video controls [muted]="true" width="100%">
                        <source src="../../../../assets/video/auto_suggest/frond end autocomplete suggestion crop.mp4" type="video/mp4">
                      </video>
                </div>
              </div>
              <div class="col-lg-6 text-col">
                <p>
                    Een UX Designer kwam met het idee om stations te voorspellen voor de gebruiker. Arjen heeft dit idee in een front-end omgezet. Door 'on click' op de input, de meest gekozen stations te tonen en vervolgens voor ieder teken dat de gebruiker invoert de mogelijke stations te tonen vanuit de NS Stations API moest de UX verbeterd worden. Arjen heeft zijn code geïnjecteerd middels Optimizely en daarna is de A/B test gestart.
                </p>
  
                  <h6>Gebruikte tools/technieken:</h6>
                  <p>Javascript, Jquery, API's, HTML, CSS, VS Code, Optimizely </p>
                  
                   
              </div>
        </div>
    </div>
  </div>