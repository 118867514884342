<div class="outer" [ngStyle]="outerStyles">

  <div class="container outer_container home_container" >
        <div class="row">

          <div class="col contact_cont">

            <div class="contact-text-container">
              <!-- <h1>Contact</h1> -->
              <div>
                <div class="container">
                  <h3>Contact formulier</h3>
                  <!-- <form action="https://formsubmit.co/your-random-string" method="POST" > -->
                  <form onsubmit="this.submit()" ngNoForm class="contactform" action="https://formsubmit.co/3bb285b82c32e682727def7e6d7c3f2c" method="POST">
                    <div class="form-group">
                      <div class="form-row">
                        <div class="col">
                          <input type="text" name="name" class="form-control" placeholder="Naam" required>
                        </div>
                        <div class="col">
                          <input type="email" name="email" class="form-control" placeholder="Email" required>
                        </div>
                        <input type="hidden" name="_next" value="https://arjenholleman.nl/">
                      </div>
                    </div>
                    <div class="form-group">
                      <textarea placeholder="Je bericht of vraag" class="form-control" name="message" rows="10" required></textarea>
                    </div>
                    <button type="submit" class="btn btn-outline-light">Verzenden</button>
                  </form>
                </div>
              </div>

              <div class="contact-text ">
                <!-- <span *ngIf="!showDetails" (click)="showDetails = true">Toon contactgegevens</span> -->
                <button *ngIf="!showDetails" (click)="showDetails = true" type="button" class="btn btn-outline-light mb-2">Toon contactgegevens</button>

                <span class="mb-2" *ngIf="showDetails">KVK: 83936475 | Email.zakelijk.ah@gmail.com | <a target="blanc" href="assets\docs\Algemene voorwaarden - Artech Software Development.pdf">Algemene voorwaarden</a></span>
                <a type="button" href="https://www.linkedin.com/in/arjenholleman/" class="btn btn-outline-light">Ga naar Linkedin</a>

                <!-- Contact me on  <a href="https://www.linkedin.com/in/arjenholleman/">Linkedin</a> -->
              </div>
            </div>

          </div>
        </div>

  </div>
</div>
