<div class="abbo_r">
    <div class="container">
        <div class="row">
          <div class="col-lg-12">    <h4>{{title}}</h4></div>
            <div class="col-lg-6 video_foto-col">
            
                <p>Let op: Proof of concept! Voor dit experiment heeft Arjen zijn script client-side geïnjecteerd in Mijn NS. Lees hieronder wat het precies voor de gebruiker kon betekenen.</p>
                <div class="video_container">
                    <video controls [muted]="true" width="100%">
                        <source src="../../../../assets/video/abo reco short mini.mp4" type="video/mp4">
                      </video>
                </div>
              </div>
              <div class="col-lg-6 text-col">
                <p>Door de reishistorie van een gebruiker (Arjen zelf in dit geval) op te halen uit Mijn NS kon dankzij Arjens script met terugwerkende kracht gegeken worden of men het goedkoopste abonnement had op dit moment. Als dit niet het geval was, kon met overstappen middels de blauwe knop.
                  Het script hierachter is zeer complex. En NS heeft nog niet besloten of dit proof of concept daadwerkelijk in Mijn NS zal komen. 

                </p>
  
                  <h6>Gebruikte tools/technieken:</h6>
                  <p>Javascript, Jquery, API's, Relay42, HTML, CSS </p>

                  <h6>Let op: Dit was een proof of concept</h6>
                  
              </div>
        </div>
    </div>
  </div>