<div class="margin" >
    <mat-card style="background-color:{{hex}};"
    (mouseover)="over()"
    (mouseout)="out()"
    class="mat-card-setheight ">
        <h3 [@hoveringState]="stateName">
            {{name}}
        </h3>
        <strong>{{subject}}</strong>
        <p>{{summary}}</p>
    </mat-card>
</div>