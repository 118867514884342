import { Component, OnInit } from '@angular/core';
import { MyDataServiceService } from "../my-data-service.service";

@Component({
  selector: 'app-interact-with-apis-http-module',
  templateUrl: './interact-with-apis-http-module.component.html',
  styleUrls: ['./interact-with-apis-http-module.component.scss']
})
export class InteractWithApisHttpModuleComponent implements OnInit {

  constructor(private myDataService:MyDataServiceService) { }

  allUsers:any;

  resUsers:any;

  myRes:any;

 

  dataObj:object={
    "name": "morpheus",
    "job": "zion resident"
}

  ngOnInit() {
  }

  showUsers(){
    this.myDataService.getUsers()
      .subscribe((data) => this.allUsers = data)
  }

  updateUserFn(){
    this.myDataService.updateUser()
    .subscribe((data) => console.log(data))

  }

  showUsersResReq(){
    this.myDataService.getResReqUsers()
      .subscribe((data) => {
        console.log(data)
        this.resUsers= data;
        console.log(this.resUsers.data)
      }) 
  }

  createResUser(val){
    console.log(val)
    this.myDataService.createResReqUser(val)
    .subscribe(data => {  
      console.log(this.myRes)
      this.myRes = data
    console.log(this.myRes)
    })
   
  }

  updateResUser(val){
    console.log(val)
    this.myDataService.updateResReqUser(val)
    .subscribe(data => this.myRes = data)
   
  }


}
