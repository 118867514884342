import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type skill = {
  title: string;
  level: string,
  CorporateExperience: string[]
}

export type themes = "regular"|"dark"|"light";

@Component({
  selector: 'app-skills-scroll',
  templateUrl: './skills-scroll.component.html',
  styleUrls: ['./skills-scroll.component.scss']
})
export class SkillsScrollComponent implements OnInit {

  defaultThemeForScrollItems$ = new BehaviorSubject<string>("dark");

  // TODO put this external
  skillsList = [{
    title:"Angular",
    level: "Expert",
    CorporateExperience: [
      "Nederlandse Spoorwegen ( '2017 - '2019 )",
      "Team Rockstars ( '2019 - '2020 )",
      "PeterConnects / Anywhere365 ( '2020 - '2021 )",
      "Priva ( '2021 - heden )",
    ]
  },
  {
    title:"Javascript",
    level: "Expert",
    CorporateExperience: [
      "CGI Nederland ( '2017' )",
      "Nederlandse Spoorwegen ( '2017 - '2019 )",
      "Team Rockstars ( '2019 - '2020 )",
      "PeterConnects / Anywhere365 ( '2020 - '2021 )",
      "Priva ( '2021 - heden )",
    ]
  },
  {
    title:"React",
    level: "Moderate",
    CorporateExperience: [
      "Team Rockstars - Logics ( '2019 - '2020 )",
    ]
  },
  {
    title:"Vue",
    level: "Moderate",
    CorporateExperience: [
      "Team Rockstars - Scildon Bank ( '2019 - '2020 )"
    ]
  },
  {
    title:"C-sharp",
    level: "intermediate",
    CorporateExperience: [
      "PeterConnects / Anywhere365 ( '2020 - '2021 )",
    ]
  },
  {
    title:"Other",
    level: "Ask me on LinkedIn",
    CorporateExperience: []
  },
  ]
  currentSkill: number = 0;

  constructor() { }

  ngOnInit() {

    window.addEventListener("keydown", event => {
      this.onkeydown(event)
    });

  }

  onnextSkillOutput(event = 0) {
    this.currentSkill = this.currentSkill + event;

    // skillsItems is  total of skill items -1
    const skillsItems = this.skillsList.length -1;

    if ( this.currentSkill > skillsItems) {
      this.currentSkill = 0;
    }
    if ( this.currentSkill < 1) {
      this.currentSkill = 0;
    }

    console.log('this.currentSkill: ', this.currentSkill);
    this.scrollToHash();
  }

  scrollToHash() {


    location.hash = "/skills-scroll#" + this.skillsList[this.currentSkill].title;
    console.log('skillEnum[this.currentSkill]: ', this.skillsList[this.currentSkill].title);
  }

  onkeydown(e) {
    var UP = 38;
    var DOWN = 40;
    var ENTER = 13;
    var ESC = 27;

    var getKey = function(e) {
      if(window.event) { return e.keyCode; }  // IE
      else if(e.which) { return e.which; }    // Netscape/Firefox/Opera
    };

    var keynum = getKey(e);

    if(keynum === UP) {
      //Move selection down
      console.log("Up!!!!!!!!")
      this.onnextSkillOutput(-1)
    }

    if(keynum === DOWN) {
      //Move selection up
      this.onnextSkillOutput(1)
    }

    if(keynum === ENTER || keynum === ESC ) {
      //Act on current selection
      this.currentSkill = 0;
      this.onnextSkillOutput()
    }
  }
}
