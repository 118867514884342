<div class="outer-container werkervaring-background container">

    <app-header
      [headerTitle]="headerTitle"
      [headerIntro]="headerIntro"
    ></app-header>

    <div class="row">
      <div *ngFor="let ervaring of ervaringen" class="col-lg-4 no-padding">
        <div>
          <app-overview-tile name="{{ervaring.company}}" subject="{{ervaring.function}}" summary="{{ervaring.summary}}" hex="{{ervaring.hex}}"></app-overview-tile>
        </div>
    </div>
    </div>
  </div>
