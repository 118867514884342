import { Component, OnInit  } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { GetInputFromSkillFormService } from "../../services/get-input-from-skill-form.service";
 

@Component({
  selector: 'app-skills2',
  templateUrl: './skills2.component.html',
  styleUrls: ['./skills2.component.scss']
})
export class Skills2Component implements OnInit {

  skillName:string='default';
  skillYears:string='0';
  skillLevel:String='0';
  skillLove:string='default';

  skillsArray:any=[]

  newSkill:object={/* 
    'skillName' : this.skillName,
    'skillYears' : this.skillYears,
    'skillLevel' : this.skillLevel,
    'skillLove' : this.skillLove, */
  }

  inputBron:object;


  constructor(private data: GetInputFromSkillFormService) { }

  ngOnInit() {
    this.data.currentInput.subscribe(inputBron => this.inputBron = inputBron)
  }

  onAddSkill(){

    /* this.newSkill = {
      'skillName' : this.skillName,
      'skillYears' : this.skillYears,
      'skillLevel' : this.skillLevel,
      'skillLove' : this.skillLove,

    } */

    this.skillsArray.push(this.inputBron)
    //console.log(this.skillsArray)
  }

  newInput(){
    this.data.changeInput({
      'skillName' : this.skillName,
      'skillYears' : this.skillYears,
      'skillLevel' : this.skillLevel,
      'skillLove' : this.skillLove,    
    })
  }

  onChange($event){
    //console.log($event)
    this.newInput()
  }

}
