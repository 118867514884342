<div class="abbo_r">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h4>{{ title }}</h4>
      </div>
      <div class="col-lg-6 video_foto-col">
        <p></p>
        <div class="video_container">
          <img
            class="img-werk"
            src="../../../../assets/werk_images/Arjen presentatie Logiqs react anguar.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="col-lg-6 text-col">
        <p>
          React vs. Angular.. Bij Logiqs bv heb ik mijn advies aan Logiqs B.V.
          mogen presenteren voor de Front-end van de nieuwe applicatie. In mijn
          advies heb ik vele uren/dagen onderzoek mogen combineren met de
          business-needs. Geweldig!
        </p>

        <h6>Gebruikte tools/technieken:</h6>
        <p>
          React, Redux, Angular, NGRX, NGXS, Context-Api, Hooks, Architectuur
          uittekenen, Javascript
        </p>
      </div>
    </div>
  </div>
</div>
