import { Component, OnInit } from '@angular/core';



export type ervaring = {
    company: string;
    startYear: number;
    endYear: number | undefined;
    function: string;
    summary: string;
    logo: string;
    hex:string;
}

@Component({
  selector: 'app-werkervaring-overzicht',
  templateUrl: './werkervaring-overzicht.component.html',
  styleUrls: ['./werkervaring-overzicht.component.scss']
})
export class WerkervaringOverzichtComponent implements OnInit {

  headerTitle = "Werkervaring";
  headerIntro = "Arjens relevante werkverleden";
  ervaringen: ervaring[];
  defaultLogoPath: '../../../assets/';


  constructor() { }

  ngOnInit(): void {
    this.ervaringen = [
      {
        company:"Anywhere365",
        logo:this.defaultLogoPath+"peco-any.jpg", //peco-any.jpg
        startYear:2020,
        endYear: undefined,
        hex:"#53A8FB",
        function:"Fullstack Developer",
        summary:"Fullstack development aan een complexe receptionist applicatie inclusief integraties met bijvoorbeeld Webex of Microsoft Teams. Voornamelijk op de Front-end maar ook C# back-end.",
      },
      {
        company:"Anywhere365",
        logo:this.defaultLogoPath+"peco-any.jpg", //peco-any.jpg
        startYear:2020,
        endYear: undefined,
        hex:"#53A8FB",
        function:"Fullstack Developer",
        summary:"Fullstack development aan een complexe receptionist applicatie inclusief integraties met bijvoorbeeld Webex of Microsoft Teams. Voornamelijk op de Front-end maar ook C# back-end.",
      },
      {
        company:"Anywhere365",
        logo:this.defaultLogoPath+"peco-any.jpg", //peco-any.jpg
        startYear:2020,
        endYear: undefined,
        hex:"#53A8FB",
        function:"Fullstack Developer",
        summary:"Fullstack development aan een complexe receptionist applicatie inclusief integraties met bijvoorbeeld Webex of Microsoft Teams. Voornamelijk op de Front-end maar ook C# back-end.",
      },
      {
        company:"Anywhere365",
        logo:this.defaultLogoPath+"peco-any.jpg", //peco-any.jpg
        startYear:2020,
        endYear: undefined,
        hex:"#53A8FB",
        function:"Fullstack Developer",
        summary:"Fullstack development aan een complexe receptionist applicatie inclusief integraties met bijvoorbeeld Webex of Microsoft Teams. Voornamelijk op de Front-end maar ook C# back-end.",
      },

  ]
  }

}
