import { Component, OnInit } from "@angular/core";
import { Skill } from "../../models/skill";
import { GetNavHeightService } from "src/app/services/get-nav-height.service";

@Component({
  selector: "app-skills",
  templateUrl: "./skills.component.html",
  styleUrls: ["./skills.component.scss"]
})
export class SkillsComponent implements OnInit {
  outerStyles: Object;
  windowHeight: number = window.innerHeight;
  navHeightVariable: number;

  oldSkills = [
    "Angular7",
    "Jquery",
    "Javascript",
    "Tagmanagement",
    "Optimizely",
    "a/b Testing"
  ];
  skills: Skill[];
  someInt: number = 35;
  someString: string = "abc";

  winHeight: any;
  navBHeight: any;
  substraction: any;

  constructor(private navHeight: GetNavHeightService) {}

  headerTitle = "Tools / Technieken / Skills / Frameworks";
  headerIntro =
    "Arjens Skills. Per skill is het aantal jaar ervaring en zijn kennisniveau aangegeven. ";

  dynamicStyles(arg) {
    return arg * 20;
  }

  widthOfHeart(arg) {
    //107 = 100%
    return arg * 21.4;
  }

  ngOnInit() {
    this.navHeight.currentVariable.subscribe(
      height => (this.navHeightVariable = height)
    );
    console.warn(this.windowHeight);
    console.warn(this.navHeightVariable);
    //alert( `${this.windowHeight - this.navHeightVariable }px`)
    this.outerStyles = {
      "min-height": `${this.windowHeight - this.navHeightVariable - 40}px`
    };

    // this.winHeight = window.innerHeight;
    // this.navBHeight = $('.navbar').offsetHeight ;

    // this.substraction = this.winHeight-this.navBHeight;

    // $('.bg_skills').height = this.substraction;

    //const node = document.querySelector('.bg_skills') as HTMLElement;

    const node = document.querySelector(".bg_skills");
    // console.warn(node)
    // node.elementView.nativeElement.offsetHeight

    this.skills = [
      {
        id: 1,
        skillName: "Angular",
        yearsOfExp: 3.5,
        skillLevelTo5: 4.5,
        loveWorkingWithItTo5: 5
      },
      {
        id: 3,
        skillName: "Javascript",
        yearsOfExp: 3.5,
        skillLevelTo5: 4.5,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 101,
        skillName: "C# ",
        yearsOfExp: 1,
        skillLevelTo5: 2.5,
        loveWorkingWithItTo5: 4
      },
      {
        id: 101,
        skillName: "React + Redux",
        yearsOfExp: 0.5,
        skillLevelTo5: 3.5,
        loveWorkingWithItTo5: 4
      },
      {
        id: 101,
        skillName: "Vue",
        yearsOfExp: 0.3,
        skillLevelTo5: 3,
        loveWorkingWithItTo5: 4
      },
      {
        id: 5,
        skillName: "Sass / Less / CSS",
        yearsOfExp: 4.5,
        skillLevelTo5: 4.5,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 3,
        skillName: "NodeJS + Express",
        yearsOfExp: 0.5,
        skillLevelTo5: 2.5,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 201,
        skillName: "NGRX",
        yearsOfExp: 0.5,
        skillLevelTo5: 3,
        loveWorkingWithItTo5: 5
      },
      {
        id: 201,
        skillName: "NGXS",
        yearsOfExp: 0.5,
        skillLevelTo5: 3,
        loveWorkingWithItTo5: 5
      },
      {
        id: 201,
        skillName: "Redux",
        yearsOfExp: 0.5,
        skillLevelTo5: 3,
        loveWorkingWithItTo5: 5
      },
      {
        id: 3,
        skillName: "MongoDB + Mongoose",
        yearsOfExp: 0.5,
        skillLevelTo5: 2,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 2,
        skillName: "JQuery",
        yearsOfExp: 3,
        skillLevelTo5: 4,
        loveWorkingWithItTo5: 5
      },
      {
        id: 4,
        skillName: "HTML",
        yearsOfExp: 4.5,
        skillLevelTo5: 5,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 3,
        skillName: "Websockets / Socket.io",
        yearsOfExp: 0.5,
        skillLevelTo5: 1,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 3,
        skillName: "PUG / Ejs / Handlebars",
        yearsOfExp: 0.5,
        skillLevelTo5: 4.5,
        loveWorkingWithItTo5: 4.5
      },
      {
        id: 7,
        skillName: "Optimizely",
        yearsOfExp: 2.5,
        skillLevelTo5: 5,
        loveWorkingWithItTo5: 2.5
      },
      {
        id: 8,
        skillName: "A/b Testing",
        yearsOfExp: 2.5,
        skillLevelTo5: 5,
        loveWorkingWithItTo5: 3.5
      },
      {
        id: 9,
        skillName: "Tag management",
        yearsOfExp: 2.5,
        skillLevelTo5: 2,
        loveWorkingWithItTo5: 3.5
      }
    ];
  }
} //end export class
